<template>
  <div>
    <slot name="task_search">
      <Row
        :gutter="8"
        class="m-b-10"
      >
        <i-col span="4">
          <Select
            size="small"
            v-model="taskQuery.type"
            placeholder="选择任务类型"
            :clearable="true"
          >
            <Option
              v-for="item in taskTypeArray"
              :key="'task_'+item.value"
              :value="item.value"
            >{{item.name}}</Option>
          </Select>
        </i-col>
        <i-col span="4">
          <DatePicker
            size="small"
            v-model="taskQuery.createDate"
            :transfer="true"
            :editable="false"
            type="date"
            style="width:100%"
            placeholder="选择发起日期"
          ></DatePicker>
        </i-col>
        <i-col span="4">
          <Select
            size="small"
            v-model="taskStatus"
            :clearable="true"
            placeholder="选择任务进度"
          >
            <Option
              v-for="item in taskStatusArray"
              :key="'status_'+item.value"
              :value="item.value"
            >{{item.name}}</Option>
          </Select>
        </i-col>
        <i-col span="6">
          <i-input
            size="small"
            enter-button
            v-model="taskQuery.keyword"
            placeholder="关键字：发起人，品牌"
          ></i-input>
        </i-col>
        <i-col span="2">
          <i-button
            size="small"
            type="primary"
            icon="ios-search"
            @click="handelSearch"
          >搜索</i-button>
        </i-col>
        <i-col
          span="4"
          class="text-right"
        >
          <Button
            v-if="entrance===1"
            size="small"
            type="primary"
            @click.stop="handelCreateTask"
          >新建任务</Button>
        </i-col>
      </Row>
    </slot>
    <div>
      <Table
        stripe
        size="small"
        max-height="420"
        :data="list"
        :columns="tableColumns"
      ></Table>
      <div class="paging_style">
        <Page
          :total="totalRow"
          :page-size="taskQuery.pageSize"
          size="small"
          show-total
          show-elevator
          show-sizer
          :page-size-opts="pagesizeSetting"
          @on-page-size-change='changePageSize'
          :current="taskQuery.pageNumber"
          @on-change="changePage"
        ></Page>
      </div>
    </div>
    <slot name="task_create">
      <Modal v-model="createModal">
        <p
          slot="header"
          class="text-center"
        >新建任务</p>
        <Select
          v-model="createType"
          placeholder="选择类型"
           @on-change="getNewTaskDefaultName"
        >
          <Option
            v-for="item in taskTypeArray.filter(x=>x.value!==7)"
            :key="'type_'+item.value"
            :value="item.value"
          >{{item.name}}</Option>
        </Select>
        <div>
          <Input
            v-model="taskName"
            size="small"
            placeholder="填写任务名称（非必填，不填写系统自动生成名称）"
          />
        </div>
        <div slot="footer">
          <Button
            ghost
            @click="createModal=false"
          >取消</Button>
          <Button
            type="primary"
            @click="handelCreateDemand"
          >确定</Button>
        </div>
      </Modal>
    </slot>
    <slot name="task_item_selected">
      <Modal
        v-if="curTaskId"
        v-model="addTaskModal"
        width="1000"
        footer-hide
      >
        <p
          slot="header"
          class="text-center"
        >{{showTitle}}</p>
        <!-- <add-task
          :taskType="curTaskType"
          :taskId="curTaskId"
        ></add-task> -->
        <component
          :is="curComponent"
          :taskType="curTaskType"
          :taskId="curTaskId"
          @handleSetSuccess="handleSetSuccess"
        ></component>
      </Modal>
      <Modal
        v-if="entrance===2"
        v-model="setTaskModal"
        width="800"
        footer-hide
      >
        <p
          slot="header"
          class="text-center"
        >任务设置</p>
        <component
          :is="curSetTaskComponent"
          :opreateType="1"
          :taskId="curTaskId"
          :taskType="curTaskType"
          :dataSource="2"
          :deviceId="deviceId"
          :selectedResourceIdArray.sync="selectedResourceIdArray"
          :selectedFaultIdArray="selectedFaultIdArray"
          @handleSetSuccess="handleInsertSuccess"
        ></component>
      </Modal>
    </slot>
  </div>
</template>

<script>
import { sysMixins } from '@/assets/mixins/sys'
// import { formartMspTaskStatus } from '@/utils/tagStatus'
import { getTaskPage, deleteTask } from '@/api/msp/task'
import { getTaskStatus } from '@/api/msp/status'
import { createTask } from '@/api/msp/demandV3'
import { getAssignManitainTask } from '@/api/msp/device'
import { getTaskDefaultName } from '@/api/msp/demand'

export default {
  mixins: [sysMixins],
  props: {
    // taskTypes: [String]
    entrance: { // 入口，1任务列表，2加入任务
      type: Number,
      default: 1
    }
  },
  components: {
    AddTask: () => import('../common/AddTask'),
    SetTempTask: () => import('../common/SetTempTask'),
    TaskDetail: () => import('./TaskDetail'),
    SetRepairTask: () => import('../common/SetRepairTask'),
    SetMaintainTask: () => import('../common/SetMaintainTask')
  },
  data () {
    return {
      curComponent: 'AddTask',
      curSetTaskComponent: '',
      taskTypeArray: [
        { value: 5, name: '维修' },
        { value: 9, name: '维护' }
      ],
      taskStatusArray: [],
      taskStatus: null,
      createModal: false,
      createType: null,
      taskName: '',
      addTaskModal: false,
      curTaskType: null,
      curTaskId: null,
      showTitle: '选择资源',

      taskQuery: {
        pageNumber: 1,
        pageSize: 15,
        status: null, // 状态(0 待派单 1已派单 null 全部)
        type: null,
        startDate: '',
        endDate: '',
        keyword: '',
        createDate: '',
        types: '5,9'
      },
      pagesizeSetting: [15, 50, 100, 200, 500],
      list: [],
      totalRow: 0,
      tableColumns: [
        // {
        //   title: '类型',
        //   align: 'center',
        //   render: (h, params) => {
        //     return h('span', this.formatTaskTypeName(params.row.type))
        //   }
        // },
        { title: '发起原因', align: 'center', key: 'name' },
        { title: '发起时间', align: 'center', key: 'createTime' },
        { title: '发起人', align: 'center', key: 'createUserName' },
        // {
        //   title: '档期',
        //   align: 'center',
        //   render: (h, params) => {
        //     if (params.row.startDate === '' || params.row.endDate === '') {
        //       return h('span', '/')
        //     } else {
        //       return h('span', GetCurrentSchedule(params.row.taskOrder.startDate, params.row.taskOrder.endDate, false))
        //     }
        //   }
        // },
        // {
        //   title: '流程进度',
        //   align: 'center',
        //   render: (h, params) => {
        //     return formartMspTaskStatus(h, params.row.status, params.row.statusName)
        //   }
        // },
        {
          title: '总数/不执行/已派单/执行中/已完成',
          align: 'center',
          render: (h, params) => {
            return h('div', [
              h('span', {
                style: {
                  color: params.row.statusQuantity.draftQuantity + params.row.statusQuantity.waittingQuantity > 0 ? '#ef4f4f' : ''
                }
              }, params.row.statusQuantity.totalQuantity),
              h('span', ` / ${params.row.statusQuantity.terminatedQuantity} / ${params.row.statusQuantity.assignedQuantity} / ${params.row.statusQuantity.executingQuantity} / ${params.row.statusQuantity.finishedQuantity}`)
            ])
          }
        },
        // { title: '备注', align: 'center', key: 'remark', ellipsis: true, tooltip: true },
        {
          title: '操作',
          align: 'center',
          render: (h, params) => {
            if (this.entrance === 1) {
              var html = [h('a', {
                style: { marginRight: '5px' },
                on: {
                  click: () => {
                    this.handleViewDemand(params.row)
                  }
                }
              }, '查看')]
              if (this.taskQuery.type !== 7) {
                if (params.row.statusQuantity.totalQuantity === 0 || params.row.statusQuantity.draftQuantity > 0) {
                  // 草稿状态，排除下刊申请
                  html.push(h('a', {
                    style: { marginRight: '5px' },
                    on: {
                      click: () => {
                        this.handelAddTask(params.row)
                      }
                    }
                  }, '添加'))
                }
                if (params.row.statusQuantity.draftQuantity === params.row.statusQuantity.totalQuantity) {
                  html.push(h('a', {
                    style: { marginRight: '5px', color: '#ef4f4f' },
                    on: {
                      click: () => {
                        this.handelDeleteTask(params.row)
                      }
                    }
                  }, '删除'))
                }
              }
              return h('div', html)
            } else {
              if (params.row.status === -1 && this.taskQuery.type !== 7) {
                return h('a', {
                  on: {
                    click: () => {
                      this.handleInsertTask(params.row)
                    }
                  }
                }, '加入')
              }
            }
          }
        }
      ],
      setTaskModal: false,
      selectedResourceIdArray: [],
      selectedFaultIdArray: []
    }
  },
  computed: {
    deviceId () {
      return this.$store.state.equipment.detailId
    },
    curDeviceRapairInfo () {
      return this.$store.state.equipment.curDeviceRapairInfo
    }
  },
  created () {
    this.getTaskStatusData()
    // this.$store.commit('set_show_tab', '2')
    if (this.entrance === 1) {
      this.getTaskData()
      this.getSvgData()
    }
  },
  methods: {
    getTaskData () {
      // this.taskQuery.type = this.taskType || this.taskQuery.type
      this.taskQuery.status = typeof (this.taskStatus) === 'number' ? JSON.stringify([this.taskStatus]) : null
      getTaskPage(this.taskQuery).then(res => {
        if (res && !res.errcode) {
          this.list = res.list
          this.totalRow = res.totalRow
          this.taskQuery.pageNumber = res.pageNumber
        } else {
          this.list = []
          this.totalRow = 0
          this.taskQuery.pageNumber = 1
        }
      })
    },
    // 作为子组件时父组件调用方法
    getTableData () {
      // this.taskQuery.types = this.taskTypes
      this.taskQuery.pageNumber = 1
      this.getTaskData()
    },
    changePageSize (pageSize) {
      this.taskQuery.pageSize = pageSize
      this.taskQuery.pageNumber = 1
      this.getTaskData()
    },
    changePage (curPage) {
      this.taskQuery.pageNumber = curPage
      this.getTaskData()
    },
    handelSearch () {
      this.taskQuery.pageNumber = 1
      this.getTaskData()
    },
    getTaskStatusData () {
      getTaskStatus().then(res => {
        if (res && !res.errcode) {
          this.taskStatusArray = res
        }
      })
    },
    getNewTaskDefaultName () {
      if (!this.createType) {
        this.taskName = ''
        return false
      }
      getTaskDefaultName({ tasktype: this.createType }).then(res => {
        if (res && !res.errcode) {
          this.taskName = res
        }
      })
    },
    handelCreateTask () {
      this.createModal = true
      this.createType = this.tasktype || null
    },
    /**
  * 确定创建需求
  */
    handelCreateDemand () {
      if (!this.createType) {
        this.$Notice.error({ desc: '请选择一个类型' })
        return false
      }
      createTask({ tasktype: this.createType, taskName: this.taskName }).then(res => {
        if (res && res.errcode === 0) {
          this.$Notice.success({ desc: '操作成功' })
          // this.taskQuery.type = this.createType
          this.createType = null
          this.getTaskData()
        }
      })
      this.createModal = false
    },
    handelAddTask (data) {
      this.curTaskId = data.id
      this.curTaskType = data.type
      this.addTaskModal = true
      this.showTitle = '选择资源'
      if (this.curTaskType === 11) {
        // 临时任务处理
        this.curComponent = 'SetTempTask'
      } else {
        this.curComponent = 'AddTask'
      }
      // this.$store.commit('set_current_taskId', data.id)
      // this.$store.commit('set_current_demand_type', this.taskQuery.type)
      // switch (this.taskQuery.type) {
      //   case 10:
      //     // 画面填充处理
      //     this.setShowLeftBlock(true)
      //     this.setLeftComponent('UninstallScreen')
      //     this.setBottomComponent('AddTask')
      //     break
      //   case 11:
      //     // 临时任务处理
      //     this.setShowLeftBlock(true)
      //     this.$store.commit('set_opreate_type', 1) // 新增操作标识设置
      //     this.setLeftComponent('SetTempTask')
      //     break

      //   default:
      //     this.setShowLeftBlock(false)
      //     this.setLeftComponent('')
      //     this.setBottomComponent('AddTask')
      //     break
      // }
    },
    /**
     * 删除任务
     */
    handelDeleteTask (data) {
      this.$Modal.confirm({
        title: '操作提示',
        content: '确定要删除该任务？',
        onOk: () => {
          deleteTask({ taskId: data.id }).then(res => {
            if (res && res.errcode === 0) {
              this.getTaskData()
              this.$Notice.success({ desc: '操作成功' })
            }
          })
        }
      })
    },
    handleViewDemand (data) {
      // this.$store.commit('set_current_demand_type', this.taskQuery.type)
      // this.$store.commit('set_current_taskId', data.id)
      // this.$store.commit('set_task_status', data.status)
      // this.setBottomComponent('TaskDetail')
      this.curTaskId = data.id
      this.curTaskType = data.type
      this.taskStatus = data.status
      this.showTitle = '查看详情'
      this.$store.commit('set_task_id', data.id)
      this.$store.commit('set_task_type', data.type)
      this.$store.commit('set_task_status', data.status)
      this.$store.commit('set_tag_type', 3)
      // this.addTaskModal = true
      // this.curComponent = 'TaskDetail'
    },
    /**
     * 获取SVG标注的数据
     */
    getSvgData () {
      const postData = {
        // assetIds: JSON.stringify([this.query.assetId]),
        // devicemodelId: this.query.devicemodel,
        // stationId: this.query.stationId
      }
      getAssignManitainTask(postData).then(res => {
        if (res && !res.errcode) {
          this.$store.commit('set_equipment_mapStations', res.filter(x => x.totalCount))
        }
      })
    },
    handleSetSuccess () {
      // this.addTaskModal = false
      this.getTaskData()
    },
    /**
     * 上报信息加入现有任务
     */
    handleInsertTask (data) {
      this.curTaskType = data.type
      this.curTaskId = data.id
      this.selectedFaultIdArray = [this.curDeviceRapairInfo.reportTroubleId]
      this.selectedResourceIdArray = [this.deviceId]
      if (this.curTaskType === 5) {
        this.curSetTaskComponent = 'SetRepairTask'
      } else {
        this.curSetTaskComponent = 'SetMaintainTask'
      }
      this.setTaskModal = true
    },
    handleInsertSuccess () {
      this.setTaskModal = false
      this.selectedFaultIdArray = []
      this.getTaskData()
      // 调用任务列表页面获取数据接口
      this.$emit('handleSetSuccess')
    }
  }

}
</script>
