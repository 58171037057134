<style lang="less" scoped>
#map_tip {
  position: fixed;
  right: 20%;
  margin-top: 15px;
  font-size: 16px;
}
</style>
<template>
  <div>
    <div id="map_tip">
      <img
        src="~@/assets/images/metro_redmarker.png"
        width="10"
      /> <span>{{mapTip}}</span>
    </div>
    <SvgLine v-if="showMapType===1"
      ref="svgline"
      @map-finished="handleMapLoadFinished"
      @on-click-station="handleClickStation"
    />

    <GeoMap v-else ref="geoMap" @map-finished="handleMapLoadFinished"  @on-click-station="handleClickStation"/>

    <SvgStation
      ref="svgStation"
      :stationSvgModal.sync="stationSvgModal"
      :stationId="chooseStationId"
      :svgShowType="5"
      :searchSchedules="[]"
      @svg-finished="svgFinished"
      @on-select="handleSelectSvgResource"
      @handleChange3dMode="handleBeginRevice3d"
    />

    <ThreeStation ref="threeStation" @three-finished="threeFinished" @handleChangeSvgMode="handleClickStation" />

    <Modal
      v-model="showRecordModal"
      width="1000"
      footer-hide
    >
      <statusDetails v-if="showRecordModal" />
    </Modal>
  </div>
</template>

<script>
import SvgLine from '@/components/svg/SvgLine'
import GeoMap from '@/components/map/GeoMap'
import SvgStation from '@/components/svg/SvgStation'
import ThreeStation from '@/components/svg/ThreeStation'
import statusDetails from './bottom/StatusDetails'

// import { getLmResourcePage } from '@/api/product/resource'
import { getStationResourceList, getStationTroubleList } from '@/api/msp/device'
import { getPublisherAssetList } from '@/api/rim/asset'

export default {
  components: {
    SvgLine, GeoMap, SvgStation, ThreeStation, statusDetails
  },
  data () {
    return {
      showMapType: this.$store.getters.token.publisherSetting.mediaType,
      mapFinished: false,
      stationSvgModal: false, // 弹窗显示SVG站点地图
      showRecordModal: false,
      chooseStationId: 0,
      publishResourceList: [] // 上刊发布的资源信息
    }
  },
  methods: {
    handleMapLoadFinished (val) {
      this.mapFinished = val
      // 仅限地铁线路图，需要隐藏其他线路
      if (val && this.showMapType === 1) {
        getPublisherAssetList().then(res => {
          if (res && !res.errcode) {
            this.$refs.svgline.hideOtherAsset(res.map(x => x.id))
          }
        })
      }
    },
    LoadMapData (stations) {
      if (this.mapFinished) { // 验证地图是否加载完毕， 否则延迟1秒再次请求
        if (this.showMapType === 1) {
          const formatStations = stations.map(x => {
            return {
              quantity: x.totalCount,
              stationId: x.stationId,
              stationName: x.stationName,
              inOrder: 1
            }
          })
          this.$refs.svgline.loadResourceStations(formatStations, 'order')
        } else {
          this.$refs.geoMap.loadResourceStations([])
        }
      } else {
        setTimeout(() => {
          this.LoadMapData(stations)
        }, 1000)
      }
    },
    handleClickStation (stationId) { // 地图点击站点事件
      this.chooseStationId = stationId
      this.stationSvgModal = true
    },

    svgFinished () { // SVG站点地图加载完毕
      if (this.tagType === 1) {
        this.getRepTroubleSvgData()
      } else {
        this.getTaskSvgData()
      }

      // const postData = {
      //   pageNumber: 1,
      //   pageSize: 15,
      //   fault: true,
      //   stationId: this.chooseStationId
      // }
      // getLmResourcePage(postData).then((res) => {
      //   this.$refs.svgStation.tagResource(res.list.map(x => {
      //     return {
      //       resourceId: x.id,
      //       stationId: x.stationId,
      //       floor: x.floor
      //     }
      //   }))
      //   this.$refs.svgStation.choseSpinShow()
      // })
    },
    threeFinished () {

    },
    /**
     * 设备列表-获取故障上报数据
     */
    getRepTroubleSvgData () {
      getStationTroubleList({ stationId: this.chooseStationId, category: this.category }).then(res => {
        if (res && !res.errcode) {
          this.$refs.svgStation.tagResource(res.map(x => {
            return {
              deviceId: x.deviceId,
              stationId: x.stationId,
              floor: x.floor
            }
          }))
          this.$refs.svgStation.choseSpinShow()
        }
      })
    },
    /**
     * 任务列表-获取任务SVG数据
     */
    getTaskSvgData () {
      const postData = {
        stationId: this.chooseStationId,
        taskId: this.taskId
      }
      getStationResourceList(postData).then(res => {
        if (res && !res.errcode) {
          this.$refs.svgStation.tagResource(res.map(x => {
            return {
              deviceId: x.deviceId,
              stationId: x.stationId,
              floor: x.floor
            }
          }))
          this.$refs.svgStation.choseSpinShow()
        }
      })
    },
    handleSelectSvgResource (resourceId) {
      this.$store.commit('set_equipment_detailId', resourceId)

      // this.$store.dispatch('setShowTechParams', { resourceId: resourceId })
      if (this.tagType === 1) {
        this.$store.commit('set_show_tab', '1')
      } else {
        this.$store.commit('set_show_tab', '2')
      }

      this.showRecordModal = true
    },
    handleBeginRevice3d (stationId) {
      this.$refs.threeStation.initData(stationId)
    }
  },
  computed: {
    mapStations () {
      return this.$store.state.equipment.mapStations
    },
    taskId () {
      return this.$store.state.equipment.taskId
    },
    tagType () {
      return this.$store.state.equipment.tagType
    },
    category () {
      return this.$store.state.equipment.curCategory
    },
    mapTip () {
      let tip = ''
      switch (this.tagType) {
        case 1:
          tip = '上报信息'
          break
        case 2:
          tip = '执行中任务'
          break
        case 3:
          tip = '任务分布'
          break
      }
      return tip
    }
  },
  watch: {
    mapStations: {
      deep: true,
      immediate: true,
      handler (val) {
        this.LoadMapData(val)
      }
    }
  }
}
</script>
